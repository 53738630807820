<template>
  <div class="tax-card">
    <div class="tax-card__content">
      <Title :level="2" class="tax-card__content__title">
        {{ name }}
      </Title>
      <span class="tax-card__content__info">
        <span class="tax-card__content__info__rate"> {{ rate }}% </span>
        <span v-if="description" class="tax-card__content__info__description">
          | {{ description }}
        </span>
      </span>
    </div>
    <ContextMenu
      v-if="menuOptions.length"
      :options="menuOptions"
      @select="$emit('menu-select', $event)"
    >
      <Icon name="dotted" isClickable color="secondary-500" />
    </ContextMenu>
  </div>
</template>

<script>
export default {
  name: "TaxCard",
  props: {
    name: {
      type: String,
      default: "",
    },
    rate: {
      type: [String, Number],
      default: 0,
    },
    description: {
      type: String,
      default: "",
    },
    menuOptions: {
      type: Array,
      default: () => [],
    },
  },
};
</script>

<style lang="scss" scoped>
.tax-card {
  padding: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  box-shadow: $box-shadow-small;
  border: 2px solid $secondary-400;
  border-radius: 8px;

  &__content {
    display: flex;
    flex-direction: column;
    gap: 8px;
    white-space: nowrap;
    overflow: hidden;

    &__title {
      text-overflow: ellipsis;
      overflow: hidden;
    }

    &__info {
      font-size: 14px;
      line-height: 24px;
      text-overflow: ellipsis;
      overflow: hidden;

      &__rate {
        color: $primary;
      }

      &__description {
        color: $secondary-500;
      }
    }
  }
}
</style>
