<template>
  <div class="taxes-page" data-v-step="7">
    <BackTitle @click="goBack">Taxes</BackTitle>
    <Guard permission="taxes.create" v-slot="{ isAvailable }">
      <IconButton v-if="isAvailable" icon="plus" isRaised @click="goToCreation">
        New tax type
      </IconButton>
    </Guard>
    <div data-v-step="9" class="taxes-page__content">
      <Loader v-if="isLoading" size="m" color="primary" class="mx-auto" />
      <div v-else-if="!taxes.length" class="empty">
        You haven't created taxes yet
      </div>
      <div v-else class="grid-2">
        <TaxCard
          v-for="item in taxes"
          :key="item.id"
          :name="item.name"
          :rate="item.rate"
          :description="item.description"
          :menu-options="menuOptions"
          @menu-select="handleContextMenu($event, item)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import BackTitle from "@/components/common/BackTitle";
import { mapActions, mapGetters, mapState } from "vuex";
import dialog from "@/plugins/dialog";
import Guard from "@/components/common/Guard";
import TaxCard from "@/components/taxes/TaxCard";

export default {
  name: "TaxesPage",
  components: { TaxCard, Guard, BackTitle },
  data() {
    return {
      isLoading: false,
    };
  },
  computed: {
    ...mapState({
      taxes: (state) => state.taxes.list,
    }),
    ...mapGetters({
      checkPermission: "users/checkPermission",
    }),
    menuOptions() {
      return [
        this.checkPermission("taxes.edit") && {
          value: "edit",
          name: "Edit",
        },
        this.checkPermission("taxes.delete") && {
          value: "delete",
          name: "Delete",
        },
      ].filter(Boolean);
    },
  },
  async created() {
    try {
      this.isLoading = true;
      await this.fetchTaxes();
    } finally {
      this.isLoading = false;
    }
  },
  methods: {
    ...mapActions({
      fetchTaxes: "taxes/fetchTaxes",
      deleteTax: "taxes/deleteTax",
    }),
    async handleContextMenu(option, tax) {
      if (option.value === "edit") {
        await this.$router.push({
          name: "EditTax",
          params: {
            id: tax.id,
          },
        });
      } else if (option.value === "delete") {
        const confirmed = await dialog.confirm({
          title: "Delete Tax?",
          message: "All tax data will be lost",
          okText: "Delete",
          cancelText: "Cancel",
        });
        if (confirmed) {
          try {
            this.isLoading = true;
            await this.deleteTax(tax.id);
            await this.fetchTaxes();
          } finally {
            this.isLoading = false;
          }
        }
      }
    },
    goBack() {
      this.$router.push({
        name: "Settings",
      });
    },
    goToCreation() {
      this.$router.push({
        name: "CreateTax",
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.taxes-page {
  display: flex;
  flex-direction: column;
  gap: 40px;

  &__content {
    display: flex;
    flex-direction: column;
  }
}
</style>
